export const color = {
	orange: '#FC6621',
	blue: '#067FAC',
	black: '#000000',
	dark: '#434343',
	grey: '#9E9E9E',
	greylight: '#CFCFCF',
	greylighter: '#E7E7E7',
	white: '#FFFFFF',
};

export const typography = {
	type: {
		default:
			'"Lineto circular book", "Helvetica Neue", Helvetica, Arial, sans-serif',
		bold:
			'"Lineto circular bold", "Helvetica Neue", Helvetica, Arial, sans-serif',
		black:
			'"Lineto circular black", "Helvetica Neue", Helvetica, Arial, sans-serif',
		code: 'Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace',
	},
	weight: {
		default: '400',
		bold: '700',
		black: '400',
	},
};
