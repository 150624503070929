import React from 'react';
import styled from 'styled-components';
import IndicatorCircle from './IndicatorCircle.js';

const StyledStepIndicator = styled.div`
	display: flex;
	justify-content: space-between;
`;

function StepIndicator({ circles }) {
	return (
		<StyledStepIndicator>
			{circles.map(circle => (
				<IndicatorCircle key={circle.id} {...circle}>
					{circle.text}
				</IndicatorCircle>
			))}
		</StyledStepIndicator>
	);
}

export default StepIndicator;
