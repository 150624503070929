import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../shared/styles';
import media from '../../utils/Breakpoints';

const StyledCodeBox = styled.textarea`
	resize: none;
	width: 100%;
	height: 435px;
	max-width: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	border: 18px solid ${color.greylighter};
	border-radius: 2px;
	padding: 18px;
	margin: 0 0 48px 0;
	font-family: Courier;
	font-size: 15px;
	line-height: 21px;
	letter-spacing: 0.47px;
	color: ${color.dark};

	${media.sl`
		margin-bottom: 0;
	`}

	${media.md`
		width: 386px;
		float: right;
	`}
`;

function CodeBox(props) {
	return <StyledCodeBox readOnly value={props.content} />;
}

CodeBox.propTypes = {
	content: PropTypes.string.isRequired,
};

CodeBox.defaultProps = {
	content: '',
};

export default CodeBox;
