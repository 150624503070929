import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, typography } from '../../shared/styles';
import media from '../../utils/Breakpoints';

const StyledPreviewBox = styled.div`
	width: 100%;
	min-height: 435px;
	height: 100%;
	overflow: hidden;
	position: relative;
	margin: 0 0 48px 0;
	background: ${props =>
		props.stripes
			? 'repeating-linear-gradient(-45deg, rgba(231, 231, 231, 0.5), rgba(231, 231, 231, 0.5) 16px, rgba(216, 216, 216, 0.5) 16px, rgba(216, 216, 216, 0.5) 32px)'
			: color.greylighter};

	${media.sl`
		margin-bottom: 0;
	`}

	${media.md`
		min-height: 485px;
		width: 380px;
		float: right;
	`}
`;

const Title = styled.p`
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	height: 26px;
	padding: 0 10px;
	color: ${color.white};
	background-color: ${color.greylight};
	font-family: ${typography.type.bold};
	font-weight: ${typography.weight.bold};
	font-size: 15px;
	line-height: 26px;
	letter-spacing: 0.47px;
	z-index: 2;
`;

const InnerWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;

	svg {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	span {
		max-width: 75%;
		line-height: 1.4;
		text-align: center;
	}

	iframe {
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
	}
`;

function PreviewBox(props) {
	return (
		<StyledPreviewBox stripes={props.stripes}>
			<Title>{props.title}</Title>
			<InnerWrapper>{props.children}</InnerWrapper>
		</StyledPreviewBox>
	);
}

PreviewBox.propTypes = {
	children: PropTypes.node,
	stripes: PropTypes.bool,
};

PreviewBox.defaultProps = {
	children: '',
};

export default PreviewBox;
