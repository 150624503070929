import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../shared/styles';
import { ReactComponent as Page } from '../../icons/icon-page.svg';
import { ReactComponent as ExternalLink } from '../../icons/icon-external-link.svg';
import { ReactComponent as WindowsPC } from '../../icons/icon-windows-pc.svg';
import media from '../../utils/Breakpoints';

const StyledSelectButton = styled.div``;

const Label = styled.label`
	${'' /* outline: 1px solid blue; */}
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	min-width: 120px;
	max-width: 134px;
	height: 182px;
	border: ${props =>
		props.isActive
			? `3px solid ${color.orange}`
			: `3px solid ${color.grey}`};
	border-radius: 8px;
	cursor: pointer;
	font-size: 15px;
	line-height: 21px;
	text-align: center;
	margin-bottom: 12px;
	user-select: none;

	${media.sl`
		width: 124px;
	`}

	${media.md`
		margin-right: 0;
	`}
`;

const Input = styled.input.attrs({ type: 'radio' })`
	display: block;
	opacity: 0;
	margin: 0;
	width: 0;
	height: 0;
	overflow: hidden;
	font-size: 0;
`;

const LabelText = styled.span`
	display: block;
	max-width: 80%;
`;

function SelectButton({ text, icon, value, id, name, isActive, ...props }) {
	let SvgIcon;
	if (icon === 'page') {
		SvgIcon = <Page />;
	} else if (icon === 'external-link') {
		SvgIcon = <ExternalLink />;
	} else if (icon === 'windows-pc') {
		SvgIcon = <WindowsPC />;
	}

	return (
		<StyledSelectButton>
			<Input {...props} id={id} type="radio" value={value} name={name} />
			<Label htmlFor={id} isActive={isActive}>
				{SvgIcon}
				<LabelText>{text}</LabelText>
			</Label>
		</StyledSelectButton>
	);
}

SelectButton.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	name: PropTypes.string,
	isActive: PropTypes.bool,
};

export default SelectButton;
