import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledStepIndicatorWithChildElements = styled.div`
	display: flex;
	justify-content: space-between;

	a,
	.indicator-link {
		display: block;
		text-decoration: none;
		margin-right: 30px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

function StepIndicatorWithChildElements(props) {
	return (
		<StyledStepIndicatorWithChildElements>
			{props.children}
		</StyledStepIndicatorWithChildElements>
	);
}

StepIndicatorWithChildElements.propTypes = {
	children: PropTypes.node,
};

StepIndicatorWithChildElements.defaultProps = {
	children: '',
};

export default StepIndicatorWithChildElements;
