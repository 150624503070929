import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';

const StyledHeader = styled.div`
	${'' /* outline: 1px solid red; */}
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 48px;

	${media.md`
		min-width: 600px;
		margin-bottom: 0;
	`}
`;

function Header(props) {
	return <StyledHeader>{props.children}</StyledHeader>;
}

Header.propTypes = {
	children: PropTypes.node,
};

Header.defaultProps = {
	children: '',
};

export default Header;
