import styled from 'styled-components';
import FormInput from '../FormInput';
import SelectWrapper from '../FormSelect/SelectWrapper';

const StopsWrapper = styled.ul`
	list-style-type: none;
	margin: 10px 0;
	padding: 0;
	overflow: auto;
	max-height: 400px;
	/*
	& ~ ${FormInput},
	& ~ ${SelectWrapper} {
		display: none;
	}
	*/
`;

export default StopsWrapper;
