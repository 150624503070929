import styled, { css } from "styled-components";

export const Table = styled.table`
	border: 0;
	border-spacing: 0;
	border-collapse: collapse;
`;

export const Tr = styled.tr`
	border-bottom: 1px solid #cfcfcf;

	&:last-child {
		border-bottom: 0;
	}
`;

const sharedStyles = css`
	padding: 6px 0;
	text-align: left;
	vertical-align: top;
`;

export const Th = styled.th`
	${sharedStyles}
	font-weight: bold;
	padding-right: 16px;
`;

export const Td = styled.td`
	${sharedStyles}
`;
