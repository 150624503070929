import createHistory from 'history/createBrowserHistory';

const history = createHistory();
if (history.location.state) {
	let state = { ...history.location.state };
	Object.keys(state).forEach(key => {
		delete state[key];
	});
	history.replace({ ...history.location, state });
}

export default history;
