import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ScrollToTopController from './utils/ScrollToTopController';
import { GlobalStyle } from './shared/global';

import Schritt1 from './Schritt1';
import Schritt2 from './Schritt2';
import Schritt3 from './Schritt3';

class App extends Component {
	render() {
		return (
			<div className="App">
				<BrowserRouter basename="/services/efaaufhp">
					<GlobalStyle />
					<ScrollToTopController />
					<Switch>
						<Route exact path="/" component={Schritt1} />
						<Route
							path="/schritt2"
							render={props => <Schritt2 {...props} />}
						/>
						<Route
							path="/schritt3"
							render={props => <Schritt3 {...props} />}
						/>
					</Switch>
				</BrowserRouter>
			</div>
		);
	}
}

export default App;
