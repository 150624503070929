import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import MainWrapper from './components/MainWrapper';
import Header from './components/Header';
import Link from './components/Link';
import ContentWrapper from './components/ContentWrapper';
import ContentWrapperColum from './components/ContentWrapper/ContentWrapperColum.js';
import Footer from './components/Footer';
import TextWrapper from './components/TextWrapper';
import FormWrapper from './components/FormWrapper';
import SelectWrapper from './components/FormSelect/SelectWrapper.js';
import SelectButtonWrapper from './components/SelectButtonWrapper';
import SelectButton from './components/SelectButton';
import FormSelect from './components/FormSelect';
import FormInput from './components/FormInput';
import PreviewBox from './components/PreviewBox';
import PreviewBoxText from './components/PreviewBox/Text';
import StopsWrapper from './components/StopsWrapper';
import StopItem from './components/StopsWrapper/StopItem';
import StopText from './components/StopsWrapper/StopText';

import Headline from './components/Headline';
import Text from './components/Text';
import Button from './components/Button';
import StepIndicatorWithChildElements from './components/StepIndicator/StepIndicatorWithChildElements.js';
import IndicatorCircle from './components/StepIndicator/IndicatorCircle.js';
import ImageBox from './components/ImageBox';

import closeIframe from './utils/closeIframe';
import fetchStops from './utils/fetchStops';
import fetchLinesById from './utils/fetchLinesById';
import fetchLinesByName from './utils/fetchLinesByName';

import { ReactComponent as IconAddress } from './assets/icons/icon-address.svg';
import { ReactComponent as IconPoi } from './assets/icons/icon-poi.svg';
import { ReactComponent as IconStop } from './assets/icons/icon-stop.svg';
import { ReactComponent as IconPlace } from './assets/icons/icon-place.svg';

import { ReactComponent as IconBus } from './assets/transportations/bus.svg';
import { ReactComponent as IconRBahn } from './assets/transportations/r-bahn.svg';
import { ReactComponent as IconSBahn } from './assets/transportations/s-bahn.svg';
// import { ReactComponent as IconRuftaxi } from './assets/transportations/ruftaxi.svg';
// import { ReactComponent as IconSeilbahn } from './assets/transportations/seilbahn.svg';
// import { ReactComponent as IconUBahn } from './assets/transportations/u-bahn.svg';
// import { ReactComponent as IconZacke } from './assets/transportations/zacke.svg';

import { ReactComponent as Close } from './icons/icon-close.svg';
import { ReactComponent as ExternalLinkBackground } from './icons/icon-external-link-with-circle.svg';
import pendlerGif from './assets/pendler.gif';

import { widgetBaseUri } from './utils/config';

class Schritt2 extends Component {
	state = {
		activeStop: this.props.location.state.activeStop || null,
		activeLine: this.props.location.state.activeLine || null,
		customName: this.props.location.state.customName || null,
		transportation: this.props.location.state.transportation || null,
		activeTabIndex: this.props.location.state.activeTabIndex || 0,
		activeType: this.props.location.state.activeType,
		locationType: this.props.location.state.locationType || 'dest',
		nextButtonIsDisabled: this.props.location.state.step3 !== true,
	};

	componentDidMount() {
		if (this.state.activeType === undefined) {
			// Prevent errors (redirect is done in render method)
			return;
		}

		const title = this.state.activeType
			.split('-')
			.map(part => `${part.charAt(0).toUpperCase()}${part.substring(1)}`)
			.join(' ');

		window._paq = window._paq || [];
		const { _paq } = window;
		_paq.push(['setGenerationTimeMs', 0]);
		_paq.push(['enableLinkTracking']);
		_paq.push(['setDocumentTitle', `${title} :: VVS Services Assistent`]);
		_paq.push(['trackPageView']);
	}

	generateStops = (stops, inputId, onClick, nextButtonIsDisabled = false) => {
		const stopItems = stops.map(stop => {
			let Icon = IconAddress;

			if (stop.type === 'stop') {
				Icon = IconStop;
			} else if (stop.type === 'poi') {
				Icon = IconPoi;
			} else if (stop.type === 'suburb') {
				Icon = IconPlace;
			}
			return (
				<StopItem
					onClick={() => {
						document.querySelector(inputId).value = stop.name;
						if (onClick) {
							onClick(stop);
						}
						this.setState({
							stops: null,
							activeStop: stop,
							nextButtonIsDisabled,
						});
					}}
				>
					<Icon />
					<StopText>
						<span>
							{stop.disassembledName
								? `${
										stop.disassembledName
								  } ${stop.buildingNumber || ''}`
								: stop.name}
						</span>
						<br />
						<span>{stop.parent.name}</span>
					</StopText>
				</StopItem>
			);
		});
		return <StopsWrapper>{stopItems}</StopsWrapper>;
	};

	generateLines = (lines, inputId, nextButtonIsDisabled = false) => {
		const lineItems = lines.map(line => {
			let Icon = IconBus;
			if (line.product.iconId === 2) {
				Icon = IconSBahn;
			} else if (line.product.iconId === 3) {
				Icon = IconBus;
			} else if (line.product.iconId === 6) {
				Icon = IconRBahn;
			}
			return (
				<StopItem
					onClick={() => {
						if (inputId) {
							document.querySelector(
								inputId,
							).value = `${line.disassembledName ||
								line.number} -> ${line.description}`;
						}
						this.setState({
							lines: null,
							activeLine: line,
							nextButtonIsDisabled,
						});
					}}
				>
					<Icon />
					<StopText>
						<span>{line.disassembledName || line.number}</span>
						{' -> '}
						<span>{line.description}</span>
					</StopText>
				</StopItem>
			);
		});

		return <StopsWrapper>{lineItems}</StopsWrapper>;
	};

	render() {
		const { activeType } = this.state;
		// console.log('Schritt 2: ' + activeType);

		if (activeType === undefined) {
			return (
				<Redirect to={{ pathname: '/' }} />
			);
		}

		let leftColumnContent;
		let rightColumnContent;
		let loadingTimeout;

		if (activeType === 'abfahrten-website') {
			let stops = null;
			if (this.state.stops) {
				stops = this.generateStops(this.state.stops, '#input2');
			}
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Abfahrtstafel für Ihre Website" />
						<Headline text="Personalisieren Sie Ihr Widget" />
						<Text text="" />
					</TextWrapper>
					<Text text="Generieren Sie sich von Ihrer individuellen Startadresse aus eine eigene Abfahrtstafel." />
					<FormWrapper spacerTopBig>
						<FormInput
							id="input2"
							name="input2"
							labeltext="Haltestelle"
							onKeyUp={input => {
								clearTimeout(loadingTimeout);
								if (input.length > 0) {
									loadingTimeout = setTimeout(() => {
										fetchStops(input, 'stopId', 'abfahrtstafel').then(
											stops => {
												this.setState({ stops });
											},
										);
									}, 300);
								} else {
									this.setState({ stops: null });
								}
							}}
							value={
								this.state.activeStop
									? this.state.activeStop.name
									: ''
							}
							searchIcon
						/>
						{stops}
					</FormWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<PreviewBox title="Vorschau">
						{this.state.activeStop ? (
							<iframe
								id="dfi-iframe"
								title="DFI Abfahrtsmonitor"
								frameBorder="0"
								marginWidth="0"
								marginHeight="0"
								style={{ backgroundColor: '#fff', border: 0 }}
								width="320"
								height="360"
								src={`https://dfisite.vvs.de/?default_stop=${
									this.state.activeStop
										? this.state.activeStop.id
										: ''
								}&time_offset=3&zoom_factor=0.325&rows=13&show_messages=1&show_position=0&lpm=400&lang=de&pk_campaign=entwicklertools&pk_kwd=abfahrtstafel`}
							/>
						) : (
							<PreviewBoxText>
								Bitte wählen Sie eine Haltestelle aus.
							</PreviewBoxText>
						)}
					</PreviewBox>
				</ContentWrapperColum>
			);
		} else if (activeType === 'abfahrten-desktop') {
			if(this.state.nextButtonIsDisabled === true) {
				this.setState({
					nextButtonIsDisabled: false,
				});
			}
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Abfahrtsmonitor" />
						<Headline text="Für Ihren Desktop" />
						<Text text="Mit dem VVS Abfahrtsmonitor für Windows 10 können Sie sich Ihre persönliche Abfahrtstafel mit dem Verkehrsmittel Ihrer Wahl zusammenstellen." />
					</TextWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<SelectButtonWrapper>
						<SelectButton
							text="Windows 10"
							icon="windows-pc"
							value="windows-10"
							id="select-windows-10"
							isActive
						/>
					</SelectButtonWrapper>
				</ContentWrapperColum>
			);
		} else if (activeType === 'abfahrten-aushangfahrplan') {
			let stops = null;
			let lines = null;
			if (this.state.stops) {
				stops = this.generateStops(
					this.state.stops,
					'#input1',
					stop => {
						fetchLinesById(stop.id, 'aushangfahrplan').then(lines => {
							this.setState({ lines });
						});
						this.setState({ activeLine: null });
					},
					true,
				);
			}
			if (this.state.lines) {
				lines = this.generateLines(this.state.lines);
			}
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Aushangfahrplan" />
						<Headline text="Personalisieren Sie Ihren Link zum Aushangfahrplan" />
						<Text text="Geben Sie zunächst eine Haltestelle als Startadresse ein,  um einen Link zum jeweiligen Aushangfahrplan generieren zu lassen. Wähle Sie danach Ihre Linie aus." />
					</TextWrapper>
					<FormWrapper spacerTopBig>
						<FormInput
							id="input1"
							name="input1"
							labeltext="Haltestelle"
							onKeyUp={input => {
								clearTimeout(loadingTimeout);
								if (input.length > 0) {
									loadingTimeout = setTimeout(() => {
										fetchStops(input, 'stopId', 'aushangfahrplan').then(
											stops => {
												this.setState({ stops });
											},
										);
									}, 300);
								} else {
									this.setState({ stops: null });
								}
							}}
							value={
								this.state.activeStop
									? this.state.activeStop.name
									: ''
							}
							searchIcon
						/>
						{this.state.activeLine && (
							<FormInput
								id="active-line"
								name="active-line"
								labeltext={`${this.state.activeLine
									.disassembledName ||
									this.state.activeLine.number} -> ${
									this.state.activeLine.description
								}`}
								value={`${this.state.activeLine
									.disassembledName ||
									this.state.activeLine.number} -> ${
									this.state.activeLine.description
								}`}
								disabled
							/>
						)}
						{stops}
						{lines}
					</FormWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<PreviewBox title="Vorschau">
						{(this.state.activeStop && this.state.activeLine) ? (
							<div>
								<ExternalLinkBackground />
								<Button
									spacerTopDouble
									textlink
									text="Aushangfahrplan aufrufen"
									href={`https://www3.vvs.de/mng/#!/XSLT_STT_REQUEST@init?pk_campaign=entwicklertools&pk_kwd=aushangfahrplan&deeplink=%7B%22odvs%22:%7B%22stt_stop%22:%22${
										this.state.activeStop
											? encodeURIComponent(this.state.activeStop.id)
											: ''
									}%22,%20%22stt_line%22:%22${
										this.state.activeLine
											? encodeURIComponent(this.state.activeLine.id)
											: ''
									}%22%7D%7D`}
									target="_blank"
									rel="noopener noreferrer"
								/>
							</div>
						) : (
							<PreviewBoxText>
								Bitte wählen Sie zuerst eine Haltestelle und Linie aus.
							</PreviewBoxText>
						)}
					</PreviewBox>
				</ContentWrapperColum>
			);
		} else if (activeType === 'abfahrten-fahrplanbuchseiten') {
			let lines = null;
			if (this.state.lines) {
				lines = this.generateLines(this.state.lines, '#input1');
			}
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Fahrplan&shy;buchseiten" />
						<Headline text="Personalisieren Sie Ihren Link zur Fahrplanbuchseite" />
						<Text text="Geben Sie eine Linie ein, für die Sie einen Link zur Fahrplanbuchseite generieren lassen wollen." />
					</TextWrapper>
					<FormWrapper spacerTopBig>
						<FormInput
							id="input1"
							name="input1"
							labeltext="Liniennummer eintragen"
							onKeyUp={input => {
								clearTimeout(loadingTimeout);
								if (input.length > 0) {
									loadingTimeout = setTimeout(() => {
										fetchLinesByName(input, 'fahrplanbuchseiten').then(lines => {
											this.setState({ lines });
										});
									}, 300);
								} else {
									this.setState({ lines: null });
								}
							}}
							value={
								this.state.activeLine
									? `${this.state.activeLine
											.disassembledName ||
											this.state.activeLine.number} -> ${
											this.state.activeLine.description
									  }`
									: ''
							}
							searchIcon
						/>
						{lines}
					</FormWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<PreviewBox title="Vorschau">
						{this.state.activeLine ? (
							<div>
								<ExternalLinkBackground />
								<Button
									spacerTopDouble
									textlink
									text="Fahrplanbuchseite aufrufen"
									href={`https://www3.vvs.de/mng/#!/XSLT_TTB_REQUEST@init?&pk_campaign=entwicklertools&pk_kwd=fahrplanbuchseiten&deeplink=%7B%22odvs%22:%7B%22ttb_line%22:%22${
										this.state.activeLine
											? encodeURIComponent(
													this.state.activeLine.id,
											)
											: ''
									}%22%7D%7D`}
									target="_blank"
									rel="noopener noreferrer"
								/>
							</div>
						) : (
							<PreviewBoxText>
								Bitte wählen Sie eine Linie aus.
							</PreviewBoxText>
						)}
					</PreviewBox>
				</ContentWrapperColum>
			);
		} else if (activeType === 'anachb-website') {
			let iframeUri = `${widgetBaseUri}a-nach-b.html`;
			const { locationType } = this.state;
			if (this.state.activeStop) {
				iframeUri += `?${locationType}Id=${this.state.activeStop.id}&${locationType}=${
					this.state.customName
				}&type=${
					this.state.transportation
						? this.state.transportation
						: 'train'
				}&pk_campaign=entwicklertools&pk_kwd=fahrplanauskunftwebsite&isPreview=1`;
			}

			let stops = null;
			if (this.state.stops) {
				stops = this.generateStops(
					this.state.stops,
					'#input3',
					stop => {
						this.setState({ customName: stop.name });
					},
				);
			}

			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Routenplaner für Bahn, Bus und Fahrrad" />
						<Headline text="Personalisieren Sie Ihr Widget" />
						<Text text="Geben Sie eine Haltestelle, Adresse oder einen POI als Start- oder Zieladresse ein und vergeben Sie eine individuelle Bezeichnung. Wählen Sie aus, ob Sie den ÖPNV oder das Fahrrad nutzen möchten." />
						<FormWrapper>
							<SelectWrapper spacerBottomSingle>
								<Text small mb0 text="Eingabe ist" />
								<FormSelect
									id="location-type-1"
									name="location-type"
									text="Start"
									value="start"
									onChange={() => {
										this.setState({
											locationType: 'start',
										});
									}}
									spacerLeftSingle
									/>
								<FormSelect
									id="location-type-2"
									name="location-type"
									text="Ziel"
									value="dest"
									onChange={() => {
										this.setState({
											locationType: 'dest',
										});
									}}
									spacerLeftSingle
									defaultChecked
								/>
							</SelectWrapper>
							<FormInput
								id="input3"
								name="input3"
								labeltext="Haltestelle, Adresse oder POI"
								value={
									this.state.activeStop
										? this.state.activeStop.name
										: ''
								}
								onKeyUp={input => {
									clearTimeout(loadingTimeout);
									if (input.length > 0) {
										loadingTimeout = setTimeout(() => {
											fetchStops(input, 'any', 'fahrplanauskunftwebseite').then(
												stops => {
													this.setState({ stops });
												},
											);
										}, 300);
									} else {
										this.setState({ stops: null });
									}
								}}
								searchIcon
							/>
							{stops}
							<FormInput
								id="input4"
								name="input4"
								labeltext="Ihre Standort-Bezeichnung"
								value={
									this.state.customName
										? this.state.customName
										: ''
								}
								onChange={input => {
									this.setState({
										customName: input,
									});
								}}
							/>
							<SelectWrapper spacerTopSingle>
								<Text small mb0 text="Vorauswahl" />
								<FormSelect
									id="default5"
									name="multipleselect"
									text="ÖPNV"
									value="oepnv"
									onChange={() => {
										this.setState({
											transportation: 'train',
										});
									}}
									spacerLeftSingle
									defaultChecked
								/>
								<FormSelect
									id="default6"
									name="multipleselect"
									text="Fahrrad"
									value="fahrrad"
									onChange={() => {
										this.setState({
											transportation: 'bike',
										});
									}}
									spacerLeftSingle
								/>
							</SelectWrapper>
						</FormWrapper>
					</TextWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<PreviewBox title="Vorschau">
						{this.state.activeStop ? (
							<iframe
								id="anachb-iframe"
								title="VVS Verbindungswidget"
								frameBorder="0"
								marginWidth="0"
								marginHeight="0"
								style={{ backgroundColor: '#fff', border: 0 }}
								width="340"
								height="400"
								src={iframeUri}
							/>
						) : (
							<PreviewBoxText>
								Haltestelle, Adresse oder POI auswählen
							</PreviewBoxText>
						)}
					</PreviewBox>
				</ContentWrapperColum>
			);
		} else if (activeType === 'anachb-fahrplanauskunft') {
			let stops = null;
			if (this.state.stops) {
				stops = this.generateStops(this.state.stops, '#input1');
			}
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Link zur Fahrplan&shy;auskunft" />
						<Headline text="Personalisieren Sie Ihren Link zur Fahrplanauskunft" />
						<Text text="Geben Sie eine Haltestelle, Adresse oder POI ein und lassen Sie sich einen Link zur Fahrplanauskunft mit vorbelegtem Ziel generieren." />
					</TextWrapper>
					<FormWrapper spacerTopBig>
						<FormInput
							id="input1"
							name="input1"
							labeltext="Haltestelle, Adresse oder POI"
							value={
								this.state.activeStop
									? this.state.activeStop.name
									: ''
							}
							onKeyUp={input => {
								clearTimeout(loadingTimeout);
								if (input.length > 0) {
									loadingTimeout = setTimeout(() => {
										fetchStops(input, 'any', 'fahrplanauskunft').then(stops => {
											this.setState({ stops });
										});
									}, 300);
								} else {
									this.setState({ stops: null });
								}
							}}
							searchIcon
						/>
						{stops}
					</FormWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<PreviewBox title="Vorschau">
						{this.state.activeStop ? (
							<div>
								<ExternalLinkBackground />
								<Button
									spacerTopDouble
									textlink
									text="Fahrplanauskunft aufrufen"
									href={`https://www3.vvs.de/mng/#!/XSLT_TRIP_REQUEST2@result?pk_campaign=entwicklertools&pk_kwd=fahrplanauskunft&language=de&deeplink=%7B%22dateTime%22:%7B%22date%22:%22now%22,%22dateFormat%22:%22%22,%22time%22:%22now%22,%22timeFormat%22:%22%22,%22useRealTime%22:true,%22isDeparture%22:true,%22timeCut%22:%5B%2201:00%22,%2204:00%22%5D,%22timeCutInterval%22:1%7D,%22via%22:%7B%22optionsList%22:%5B%5D,%22optionsListItem%22:%7B%22type%22:%22any%22,%22dwellTime%22:%220%22,%22enabled%22:true%7D%7D,%22odvs%22:%7B%22orig%22:%22%22,%22dest%22:%22${
										this.state.activeStop
											? this.state.activeStop.id
											: ''
									}%22%7D%7D`}
									target="_blank"
									rel="noopener noreferrer"
								/>
							</div>
						) : (
							<PreviewBoxText>
								Bitte geben Sie eine Haltestelle, Adresse oder POI an.
							</PreviewBoxText>
						)}
					</PreviewBox>
				</ContentWrapperColum>
			);
		} else if (activeType === 'stadtplan-link') {
			let stops = null;
			if (this.state.stops) {
				stops = this.generateStops(this.state.stops, '#input1');
			}
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Link zum Stadtplan&shy;ausschnitt" />
						<Headline text="Personalisieren Sie Ihren Link zum Stadtplanausschnitt" />
						<Text text="Geben Sie eine Haltestelle, Adresse oder POI ein und lassen Sie sich einen Stadtplanausschnitt für diesen Bereich als PDF generieren." />
					</TextWrapper>
					<FormWrapper spacerTopBig>
						<FormInput
							id="input1"
							name="input1"
							labeltext="Haltestelle, Adresse oder POI"
							value={
								this.state.activeStop
									? this.state.activeStop.name
									: ''
							}
							onKeyUp={input => {
								clearTimeout(loadingTimeout);
								if (input.length > 0) {
									loadingTimeout = setTimeout(() => {
										fetchStops(input, 'any', 'stadtplanausschnitt').then(stops => {
											this.setState({ stops });
										});
									}, 300);
								} else {
									this.setState({ stops: null });
								}
							}}
							searchIcon
						/>
						{stops}
					</FormWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<PreviewBox title="Vorschau">
						{this.state.activeStop ? (
							<div>
								<ExternalLinkBackground />
								<Button
									spacerTopDouble
									textlink
									text="Stadtplanausschnitt aufrufen"
									href={`https://www3.vvs.de/mng/#!/XSLT_ROP_REQUEST@stops?language=de&pk_campaign=entwicklertools&pk_kwd=stadtplanausschnitt&deeplink=%7B%22odvs%22:%7B%22rop_stop%22:%22${
										this.state.activeStop
											? encodeURIComponent(this.state.activeStop.id)
											: ''
									}%22%7D%7D`}
									target="_blank"
									rel="noopener noreferrer"
								/>
							</div>
						) : (
							<PreviewBoxText>
								Bitte geben Sie eine Haltestelle, Adresse oder
								POI an.
							</PreviewBoxText>
						)}
					</PreviewBox>
				</ContentWrapperColum>
			);
		} else if (activeType === 'pendler-auskunft') {
			let stops = null;
			if (this.state.stops) {
				stops = this.generateStops(this.state.stops, '#input2');
			}
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Pendler&shy;auskunft für Ihre Website" />
						<Headline text="Personalisieren Sie Ihr Widget" />
						<Text text="Geben Sie eine Haltestelle, Adresse oder POI als Zieladresse ein und lassen Sie sich ein Pendlerwidget für den täglichen Weg zur Arbeit und nach Hause generieren." />
					</TextWrapper>
					<FormWrapper spacerTopBig>
						<FormInput
							id="input2"
							name="input2"
							labeltext="Haltestelle, Adresse oder POI"
							value={
								this.state.activeStop
									? this.state.activeStop.name
									: ''
							}
							onKeyUp={input => {
								clearTimeout(loadingTimeout);
								if (input.length > 0) {
									loadingTimeout = setTimeout(() => {
										fetchStops(input, 'any', 'pendlerauskunft').then(stops => {
											this.setState({ stops });
										});
									}, 300);
								} else {
									this.setState({ stops: null });
								}
							}}
							searchIcon
						/>
						{stops}
					</FormWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<PreviewBox title="Vorschau">
						<ImageBox
							src={pendlerGif}
							alt="Vorschau des Pendler-Widgets"
							width="340px"
						/>
					</PreviewBox>
				</ContentWrapperColum>
			);
		}

		return (
			<MainWrapper>
				<Header>
					<StepIndicatorWithChildElements>
						<Link
							className="indicator-link"
							to={{
								pathname: '/',
								state: this.state,
							}}
							replaceHistory
						>
							<IndicatorCircle
								id={1}
								text="1"
								state="isClickable"
							/>
						</Link>
						<IndicatorCircle id={2} text="2" state="isSelected" />
						<IndicatorCircle id={3} text="3" />
					</StepIndicatorWithChildElements>
					<Button text="Close" onClick={closeIframe} containsIcon>
						<Close />
					</Button>
				</Header>
				<ContentWrapper>
					{leftColumnContent}
					{rightColumnContent}
				</ContentWrapper>
				<Footer>
					<Link
						to={{
							pathname: '/schritt3',
							state: this.state,
						}}
						replaceHistory
					>
						<Button
							text="Weiter"
							handleRoute="x"
							isDisabled={this.state.nextButtonIsDisabled}
						/>
					</Link>
					<Link
						to={{
							pathname: '/',
							state: this.state,
						}}
						replaceHistory
					>
						<Button text="Zurück" handleRoute="x" secondary />
					</Link>
				</Footer>
			</MainWrapper>
		);
	}
}

export default Schritt2;
