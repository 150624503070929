import React from 'react';
import { Link as ReactRouterLink, withRouter } from 'react-router-dom';

const Link = props => {
	if (props.replaceHistory) {
		return (
			<div
				className={props.className}
				onClick={e => {
					if (props.onClick) {
						props.onClick(e);
					}
					props.history.replace(props.to);
				}}
			>
				{props.children}
			</div>
		);
	}

	return (
		<ReactRouterLink {...props}/>
	);
};

export default withRouter(Link);
