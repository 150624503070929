import { apiBase } from "./config";

const linesByNameCache = {};

export default async (name, pkKwd = '') => {
	if (linesByNameCache[name] !== undefined) {
		return linesByNameCache[name];
	}

	const response = await fetch(`${apiBase}XML_SERVINGLINES_REQUEST?SpEncId=0&coordOutputFormat=EPSG:4326&lineReqType=4&lsShowTrainsExplicit=1&mergeDir=1&mode=line&outputFormat=rapidJSON&serverInfo=0&lineName=${name}&pk_campaign=entwicklertools&pk_kwd=${pkKwd}`);
	const json = await response.json();
	linesByNameCache[name] = json.lines;
	return json.lines;
}
