import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Redirect } from 'react-router-dom';

import MainWrapper from './components/MainWrapper';
import Header from './components/Header';
import Link from './components/Link';
import ContentWrapper from './components/ContentWrapper';
import ContentWrapperColum from './components/ContentWrapper/ContentWrapperColum';
import Footer from './components/Footer';
import { Table, Tr, Th, Td } from './components/Table';
import TextWrapper from './components/TextWrapper';
import Text from './components/Text';
import CodeBox from './components/CodeBox';

import Headline from './components/Headline';
import Button from './components/Button';
import StepIndicatorWithChildElements from './components/StepIndicator/StepIndicatorWithChildElements.js';
import IndicatorCircle from './components/StepIndicator/IndicatorCircle';
import closeIframe from './utils/closeIframe';

import { widgetBaseUri } from './utils/config';

import { ReactComponent as Close } from './icons/icon-close.svg';

class Schritt3 extends Component {
	state = {
		activeStop: this.props.location.state.activeStop || null,
		activeLine: this.props.location.state.activeLine || null,
		customName: this.props.location.state.customName || null,
		locationType: this.props.location.state.locationType || null,
		transportation: this.props.location.state.transportation || null,
		activeTabIndex: this.props.location.state.activeTabIndex || 0,
		activeType: this.props.location.state.activeType,
		step3: true,
		nextButtonIsDisabled: true,
		copySuccess: '',
	};

	componentDidMount() {
		if (this.state.activeType === undefined) {
			// Prevent errors (redirect is done in render method)
			return;
		}

		const title = this.state.activeType
			.split('-')
			.map(part => `${part.charAt(0).toUpperCase()}${part.substring(1)}`)
			.join(' ');

		window._paq = window._paq || [];
		const { _paq } = window;
		_paq.push(['setGenerationTimeMs', 0]);
		_paq.push(['enableLinkTracking']);
		_paq.push(['setDocumentTitle', `Abschluss - ${title} :: VVS Services Assistent`]);
		_paq.push(['trackPageView']);
	}

	render() {
		const { activeType } = this.state;
		// console.log('Schritt 3: ' + activeType);

		if (activeType === undefined) {
			return (
				<Redirect to={{ pathname: '/' }} />
			);
		}

		let leftColumnContent;
		let rightColumnContent;
		let lastStepButton;

		const CodeBoxCodeAbfahrtenWebsite = `<iframe frameborder="0" marginwidth="0" marginheight="0" style="border: 0;" width="992" height="565" src="https://dfisite.vvs.de/?default_stop=${
			this.state.activeStop
				? encodeURIComponent(this.state.activeStop.id)
				: ''
		}&time_offset=3&zoom_factor=1&rows=7&show_messages=1&show_position=0&lpm=400&lang=de&pk_campaign=entwicklertools&pk_kwd=abfahrtstafel"></iframe>`;

		const CodeBoxLinkAushangfahrplan = `https://www3.vvs.de/mng/#!/XSLT_STT_REQUEST@init?pk_campaign=entwicklertools&pk_kwd=aushangfahrplan&deeplink=%7B%22odvs%22:%7B%22stt_stop%22:%22${
			this.state.activeStop
				? encodeURIComponent(this.state.activeStop.id)
				: ''
		}%22,%20%22stt_line%22:%22${
			this.state.activeLine
				? encodeURIComponent(this.state.activeLine.id)
				: ''
		}%22%7D%7D`;

		const CodeBoxLinkFahrplanbuchseiten = `https://www3.vvs.de/mng/#!/XSLT_TTB_REQUEST@init?pk_campaign=entwicklertools&pk_kwd=fahrplanbuchseiten&deeplink=%7B%22odvs%22:%7B%22ttb_line%22:%22${
			this.state.activeLine
				? encodeURIComponent(
						this.state.activeLine.id,
				)
				: ''
		}%22%7D%7D`;

		const CodeBoxCodeAnachbWebsite = `<iframe title="VVS Verbindungswidget" frameborder="0" style="border: 0;" width="340" height="400" src="${widgetBaseUri}a-nach-b.html?${this.state.locationType}Id=${
			this.state.activeStop
				? encodeURIComponent(this.state.activeStop.id)
				: ''
		}&${this.state.locationType}=${encodeURIComponent(this.state.customName)}&type=${
			this.state.transportation
				? encodeURIComponent(this.state.transportation)
				: 'train'
		}"></iframe>`;

		const CodeBoxLinkFahrplanauskunft = `https://www3.vvs.de/mng/#!/XSLT_TRIP_REQUEST2@result?language=de&pk_campaign=entwicklertools&pk_kwd=fahrplanauskunft&deeplink=%7B%22dateTime%22:%7B%22date%22:%22now%22,%22dateFormat%22:%22%22,%22time%22:%22now%22,%22timeFormat%22:%22%22,%22useRealTime%22:true,%22isDeparture%22:true,%22timeCut%22:%5B%2201:00%22,%2204:00%22%5D,%22timeCutInterval%22:1%7D,%22via%22:%7B%22optionsList%22:%5B%5D,%22optionsListItem%22:%7B%22type%22:%22any%22,%22dwellTime%22:%220%22,%22enabled%22:true%7D%7D,%22odvs%22:%7B%22orig%22:%22%22,%22dest%22:%22${
			this.state.activeStop
				? encodeURIComponent(this.state.activeStop.id)
				: ''
		}%22%7D%7D`;

		const CodeBoxLinkStadtplanausschnitt = `https://www3.vvs.de/mng/#!/XSLT_ROP_REQUEST@stops?language=de&pk_campaign=entwicklertools&pk_kwd=stadtplanausschnitt&deeplink=%7B%22odvs%22:%7B%22rop_stop%22:%22${
			this.state.activeStop
				? encodeURIComponent(this.state.activeStop.id)
				: ''
		}%22%7D%7D`;

		const CodeBoxCodePendlerAuskunft = `<iframe title="VVS Pendler Monitor" frameborder="0" style="border: 0;" width="340" height="400" src="${widgetBaseUri}pendler.html?origin=${
			this.state.activeStop
				? encodeURIComponent(this.state.activeStop.id)
				: ''
		}"></iframe>`;

		if (activeType === 'abfahrten-website') {
			leftColumnContent = (
				<ContentWrapperColum wider>
					<TextWrapper>
						<Headline colored text="Ihr Code" />
						<Headline text="Kopieren Sie Ihren Code zum Einbetten" />
						{/* <Text text="Den fertig generierten Code müssen Sie nur noch in Ihre Website integrieren oder von Ihrem Web-Administrator einsetzen lassen. Die Funktionalität sowie das Layout mit Farben und Schriften sind bereits im Code enthalten." /> */}
						<Text text="Den fertig generierten Code müssen Sie nur noch in Ihre Website integrieren oder von Ihrem Web-Administrator einsetzen lassen." />

						<Text text="Folgende URL-Parameter können Sie noch anpassen:" />
						<Table>
							<Tr>
								<Th>time_offset</Th>
								<Td>Minuten bis zur gewünschten Abfahrtszeit</Td>
							</Tr>
							<Tr>
								<Th>zoom_factor</Th>
								<Td>Zoomstufe der Widget-Skalierung</Td>
							</Tr>
							<Tr>
								<Th>rows</Th>
								<Td>Anzahl der angezeigten Abfahrten</Td>
							</Tr>
							<Tr>
								<Th>show_messages</Th>
								<Td>Ausgabe von Störungsmeldungen (0=aus, 1=an)</Td>
							</Tr>
							<Tr>
								<Th>show_position</Th>
								<Td>Ausgabe des Abfahrtssteigs (0=aus, 1=an)</Td>
							</Tr>
							<Tr>
								<Th>lpm</Th>
								<Td>Geschwindigkeit der Laufschrift</Td>
							</Tr>
						</Table>
					</TextWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<CodeBox content={CodeBoxCodeAbfahrtenWebsite} />
				</ContentWrapperColum>
			);
			lastStepButton = (
				<CopyToClipboard
					text={CodeBoxCodeAbfahrtenWebsite}
					onCopy={() => {
						this.setState({ copySuccess: 'kopiert' });
					}}
				>
					<Button text="Code kopieren" />
				</CopyToClipboard>
			);
		} else if (activeType === 'abfahrten-desktop') {
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Download" />
						<Headline text="Der VVS Abfahrtsmonitor ist im Microsoft Store erhältlich" />
						<Text text="Dort können Sie ohne Registrierung das kostenlose Widget beziehen." />
					</TextWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = <ContentWrapperColum></ContentWrapperColum>;
			lastStepButton = (
				<Button
					text="Microsoft Store"
					href="https://www.microsoft.com/de-de/p/vvs-abfahrtsmonitor/9n43n2bs8w84?activetab=pivot:overviewtab"
					target="_blank"
					rel="noopener noreferrer"
				/>
			);
		} else if (activeType === 'abfahrten-aushangfahrplan') {
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Aushangfahrplan" />
						<Headline text="Kopieren Sie Ihren Link" />
						<Text text="Diesen Link können Sie auf Ihrer Website ausgeben und so den Aushangfahrplan als PDF zum Download bereitstellen." />
					</TextWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<CodeBox content={CodeBoxLinkAushangfahrplan} />
				</ContentWrapperColum>
			);
			lastStepButton = (
				<CopyToClipboard
					text={CodeBoxLinkAushangfahrplan}
					onCopy={() => {
						this.setState({ copySuccess: 'kopiert' });
					}}
				>
					<Button text="Link kopieren" />
				</CopyToClipboard>
			);
		} else if (activeType === 'abfahrten-fahrplanbuchseiten') {
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Fahrplanbuchseiten" />
						<Headline text="Kopieren Sie Ihren Link" />
						<Text text="Diesen Link können Sie auf Ihrer Website ausgeben und so den Fahplanbuchseite als PDF zum Download bereitstellen." />
					</TextWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<CodeBox content={CodeBoxLinkFahrplanbuchseiten} />
				</ContentWrapperColum>
			);
			lastStepButton = (
				<CopyToClipboard
					text={CodeBoxLinkFahrplanbuchseiten}
					onCopy={() => {
						this.setState({ copySuccess: 'kopiert' });
					}}
				>
					<Button text="Link kopieren" />
				</CopyToClipboard>
			);
		} else if (activeType === 'anachb-website') {
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Ihr Code" />
						<Headline text="Kopieren Sie Ihren Code zum Einbetten" />
						<Text text="Den fertig generierten Code müssen Sie nur noch in Ihre Website integrieren oder von Ihrem Web-Administrator einsetzen lassen. Die Funktionalität sowie das Layout mit Farben und Schriften sind bereits im Code enthalten." />
					</TextWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<CodeBox content={CodeBoxCodeAnachbWebsite} />
				</ContentWrapperColum>
			);
			lastStepButton = (
				<CopyToClipboard
					text={CodeBoxCodeAnachbWebsite}
					onCopy={() => {
						this.setState({ copySuccess: 'kopiert' });
					}}
				>
					<Button text="Code kopieren" />
				</CopyToClipboard>
			);
		} else if (activeType === 'anachb-fahrplanauskunft') {
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Fahrplanauskunft" />
						<Headline text="Kopieren Sie Ihren Link" />
						<Text text="Diesen Link können Sie auf Ihrer Website ausgeben. Damit verlinken Sie zum Angebot der VVS." />
					</TextWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<CodeBox content={CodeBoxLinkFahrplanauskunft} />
				</ContentWrapperColum>
			);
			lastStepButton = (
				<CopyToClipboard
					text={CodeBoxLinkFahrplanauskunft}
					onCopy={() => {
						this.setState({ copySuccess: 'kopiert' });
					}}
				>
					<Button text="Link kopieren" />
				</CopyToClipboard>
			);
		} else if (activeType === 'stadtplan-link') {
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Stadtplanausschnitt" />
						<Headline text="Kopieren Sie Ihren Link" />
						<Text text="Diesen Link können Sie auf Ihrer Website ausgeben und so den Stadtplanausschnitt als PDF zum Download bereitstellen." />
					</TextWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<CodeBox content={CodeBoxLinkStadtplanausschnitt} />
				</ContentWrapperColum>
			);
			lastStepButton = (
				<CopyToClipboard
					text={CodeBoxLinkStadtplanausschnitt}
					onCopy={() => {
						this.setState({ copySuccess: 'kopiert' });
					}}
				>
					<Button text="Link kopieren" />
				</CopyToClipboard>
			);
		} else if (activeType === 'pendler-auskunft') {
			leftColumnContent = (
				<ContentWrapperColum>
					<TextWrapper>
						<Headline colored text="Ihr Code" />
						<Headline text="Kopieren Sie Ihren Code zum Einbetten" />
						<Text text="Den fertig generierten Code müssen Sie nur noch in Ihre Website integrieren oder von Ihrem Web-Administrator einsetzen lassen. Die Funktionalität sowie das Layout mit Farben und Schriften sind bereits im Code enthalten." />
					</TextWrapper>
				</ContentWrapperColum>
			);
			rightColumnContent = (
				<ContentWrapperColum>
					<CodeBox content={CodeBoxCodePendlerAuskunft} />
				</ContentWrapperColum>
			);
			lastStepButton = (
				<CopyToClipboard
					text={CodeBoxCodePendlerAuskunft}
					onCopy={() => {
						this.setState({ copySuccess: 'kopiert' });
					}}
				>
					<Button text="Code kopieren" />
				</CopyToClipboard>
			);
		}

		return (
			<MainWrapper>
				<Header>
					<StepIndicatorWithChildElements>
						<Link
							className="indicator-link"
							to={{
								pathname: '/',
								state: { activeType: activeType },
							}}
							replaceHistory
						>
							<IndicatorCircle
								id={1}
								text="1"
								state="isClickable"
							/>
						</Link>
						<Link
							className="indicator-link"
							to={{
								pathname: '/schritt2',
								state: { activeType: activeType },
							}}
							replaceHistory
						>
							<IndicatorCircle
								id={2}
								text="2"
								state="isClickable"
							/>
						</Link>
						<IndicatorCircle id={3} text="3" state="isSelected" />
					</StepIndicatorWithChildElements>
					<Button text="Close" onClick={closeIframe} containsIcon>
						<Close />
					</Button>
				</Header>
				<ContentWrapper>
					{leftColumnContent}
					{rightColumnContent}
				</ContentWrapper>
				<Footer>
					<div>
						<span>{this.state.copySuccess}</span>
						{lastStepButton}
					</div>
					<Link
						to={{
							pathname: '/schritt2',
							state: this.state,
						}}
						replaceHistory
					>
						<Button text="Zurück" handleRoute="x" secondary />
					</Link>
				</Footer>
			</MainWrapper>
		);
	}
}

export default Schritt3;
