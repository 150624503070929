import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTextWrapper = styled.div`
	/* outline: 1px solid red; */
	${'' /* max-width: 386px; */}

	p:last-child {
		margin-bottom: 0;
	}
`;

function TextWrapper(props) {
	return <StyledTextWrapper>{props.children}</StyledTextWrapper>;
}

TextWrapper.propTypes = {
	children: PropTypes.node,
};

TextWrapper.defaultProps = {
	children: '',
};

export default TextWrapper;
