import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import closeIframe from './utils/closeIframe';
import * as serviceWorker from './serviceWorker';
import './shared/history';

ReactDOM.render(<App />, document.getElementById('root'));

document.body.addEventListener('click', (e) => {
	let { target } = e;
	while (target !== null) {
		if (target.classList && target.classList.contains('main-wrapper')) {
			break;
		}
		target = target.parentNode;
	}
	if (target === null) {
		closeIframe();
	}
}, false);
document.addEventListener('keydown', e => {
	if (e.key !== undefined) {
		if (e.key === 'Escape') {
			closeIframe();
		}
	} else if (e.keyCode === 27) {
		closeIframe();
	}
}, false);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
