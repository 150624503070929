import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../shared/styles';
import TabEntry from './TabEntry';
import media from '../../utils/Breakpoints';

const Wrapper = styled.div`
	overflow: hidden;

	${media.md`
		width: 395px;
		float: right;
	`}
`;

const TabWrapper = styled.ul`
	border-bottom: 1px solid ${color.grey};
	display: flex;
	flex-flow: wrap;
	justify-content: space-around;
	align-items: flex-start;
	list-style-type: none;
	margin: 0;
	padding: 0;

	${media.sl`
		justify-content: space-between;
		flex-flow: row nowrap;
	`}
`;

const TabItems = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	transition: transform 0.2s ease;
	width: 100%;
	max-width: 345px;

	${media.iphoneplus`
		max-width: 380px;
	`}

	${media.sm`
		max-width: none;
	`}
`;

class Tabs extends Component {
	state = {
		activeIndex: this.props.activeIndex,
	};

	updateTab = activeIndex => () => {
		this.props.onTabChange(activeIndex);
		this.setState({ activeIndex });
	};

	render() {
		const { entries, children } = this.props;
		const { activeIndex } = this.state;

		const tabs = entries.map((entry, i) => (
			<TabEntry
				key={`tab-${entry}`}
				active={activeIndex === i}
				onClick={this.updateTab(i)}
			>
				{entry}
			</TabEntry>
		));

		return (
			<Wrapper>
				<TabWrapper>{tabs}</TabWrapper>
				<TabItems
					style={{ transform: `translateX(${activeIndex * -100}%)` }}
				>
					{Children.toArray(children)}
				</TabItems>
			</Wrapper>
		);
	}
}

Tabs.propTypes = {
	entries: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
	onTabChange: PropTypes.func,
	children: PropTypes.node.isRequired,
};

Tabs.defaultProps = {
	onTabChange: () => {},
};

export default Tabs;
