import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';

const StyledMainWrapper = styled.div`
	${'' /* outline: 1px solid black; */}
	width: 100%;
	max-width: 435px;
	margin-left: auto;
	margin-right: auto;
	padding: 15px;
	background-color: #fff;

	${media.sm`
		padding: 20px;
	`}

	${media.md`
		width: 1007px;
		max-width: none;
		padding: 60px 75px 44px 75px;
	`}
`;

function MainWrapper(props) {
	return (
		<StyledMainWrapper className="main-wrapper">
			{props.children}
		</StyledMainWrapper>
	);
}

MainWrapper.propTypes = {
	children: PropTypes.node,
};

MainWrapper.defaultProps = {
	children: '',
};

export default MainWrapper;
