import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { color } from '../../shared/styles';
import media from '../../utils/Breakpoints';

const buttonStyles = css`
	background-color: ${color.blue};
	color: #fff;
	display: inline-block;
	padding: 13px 20px;
	text-decoration: none;
	border: 2px solid transparent;
	border-radius: 2px;
	-webkit-font-smoothing: antialiased;
	-webkit-touch-callout: none;
	user-select: none;
	cursor: pointer;
	outline: 0;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 17px;
	line-height: 21px;
	margin-top: ${props => (props.spacerTopDouble ? '24px' : '0')};

	&:hover {
		border-color: ${color.blue};
		background-color: ${color.white};
		color: ${color.blue};
	}

	&:active {
		border-color: ${color.dark};
		background-color: ${color.dark};
		color: ${color.white};
	}

	${media.sl`
		padding: 13px 30px;
	`}

	${media.md`
		padding: ${props => (props.textlink ? '0 0 5px 0' : '13px 50px')};
	`}

	${props =>
		props.secondary &&
		`
		border-color: ${color.white};
		background-color: ${color.white};
		color: ${color.blue};

		&:hover {
			border-color: ${color.blue};
		}
	`}

	${props =>
		props.textlink &&
		`
		color: ${color.dark}
		padding: 0 0 5px 0;
		text-transform: none;
		background-color: transparent;
		border: 0;
		border-bottom: 3px solid ${color.orange};

		&:hover {
			color: ${color.orange}
			border-bottom: 3px solid ${color.orange};
			background-color: transparent;
		}
	`}

	${props =>
		props.disabled &&
		`
		cursor: not-allowed !important;
		color: rgba(255,255,255,.5);

		&:hover {
			border-color: transparent;
			background-color: ${color.blue};
			color: rgba(255,255,255,.5);
		}
	`}


`;

const A = styled.a`
	${buttonStyles};
`;

const StyledButton = styled.button`
	${buttonStyles};
`;

const StyledIconButton = styled.button`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	background-color: transparent;
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-touch-callout: none;
	user-select: none;
	cursor: pointer;
	outline: 0;
	padding: 0;
	border: none;
	border-radius: 50%;

	svg {
		display: block;
	}

	&:hover {
		background-color: ${color.grey};

		svg path {
			fill: ${color.white} !important;
		}
	}

	&:active {
		background-color: ${color.dark};

		svg path {
			fill: ${color.white} !important;
		}
	}
`;

function Button(props) {
	// Render an anchor tag
	let button = (
		<A
			href={props.href}
			onClick={props.onClick}
			secondary={props.secondary}
			{...props}
		>
			{props.text}
		</A>
	);

	// If the Button has a handleRoute prop, we want to render a button
	if (props.handleRoute) {
		button = (
			<StyledButton
				onClick={props.onClick}
				secondary={props.secondary}
				disabled={props.isDisabled}
			>
				{props.text}
			</StyledButton>
		);
	}

	// Icon Button
	if (props.containsIcon) {
		button = (
			<StyledIconButton href={props.href} onClick={props.onClick}>
				{props.children}
			</StyledIconButton>
		);
	}

	return <div>{button}</div>;
}

Button.propTypes = {
	text: PropTypes.node.isRequired,
	children: PropTypes.node,
	handleRoute: PropTypes.string,
	href: PropTypes.string,
	textlink: PropTypes.bool,
	secondary: PropTypes.bool,
	close: PropTypes.string,
	onClick: PropTypes.func,
	containsIcon: PropTypes.bool,
	isDisabled: PropTypes.bool,
	spacerTopDouble: PropTypes.bool,
};

export default Button;
