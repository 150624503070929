import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';

const StyledContentWrapper = styled.div`
	${'' /* outline: 1px solid pink; */}
	width: 100%;

	${media.md`
		display: flex;
		justify-content: space-between;
		width: 857px;
		min-height: 440px;
		padding-top: 60px;
		padding-bottom: 12px;
	`}
`;

function ContentWrapper(props) {
	return <StyledContentWrapper>{props.children}</StyledContentWrapper>;
}

ContentWrapper.propTypes = {
	children: PropTypes.node,
};

ContentWrapper.defaultProps = {
	children: '',
};

export default ContentWrapper;
