import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';

const StyledSelectButtonWrapper = styled.div`
	display: flex;
	flex-flow: wrap;
	justify-content: space-around;

	${media.sl`
		justify-content: ${props =>
			props.specialForTwoItems ? 'flex-start' : 'space-between'};
		width: 395px;

		> div:first-child {
			margin-right: ${props => (props.specialForTwoItems ? '11px' : '0')};
		}
	`}
`;

function SelectButtonWrapper(props) {
	return (
		<StyledSelectButtonWrapper {...props}>
			{props.children}
		</StyledSelectButtonWrapper>
	);
}

SelectButtonWrapper.propTypes = {
	children: PropTypes.node,
	specialForTwoItems: PropTypes.bool,
};

SelectButtonWrapper.defaultProps = {
	children: '',
};

export default SelectButtonWrapper;
