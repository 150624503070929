import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../shared/styles';
import media from '../../utils/Breakpoints';

const StyledFormSelect = styled.div`
	position: relative;
	margin-left: ${props => (props.spacerLeftSingle ? '12px' : '0')};
	margin-bottom: 12px;

	${media.sl`
		margin-bottom: 0;
	`}
`;

const Label = styled.label`
	position: relative;
	display: block;
	width: 124px;
	height: 51px;
	border: 3px solid transparent;
	border-radius: 3px;
	background-color: ${color.white};
	cursor: pointer;
	color: ${color.dark};
	font-size: 16px;
	line-height: 47px;
	text-align: center;
	user-select: none;

	&:hover {
		border-color: ${color.grey};
	}
`;

const Input = styled.input.attrs({ type: 'radio' })`
	display: block;
	opacity: 0;
	margin: 0;
	width: 0;
	height: 0;
	overflow: hidden;
	font-size: 0;

	&:checked + ${Label} {
		border-color: ${color.orange};
	}

	&:disabled + ${Label} {
		color: rgba(67, 67, 67, 0.5);
		cursor: not-allowed !important;

		&:hover {
			border-color: transparent;
		}
	}
`;

function FormSelect(props) {
	return (
		<StyledFormSelect spacerLeftSingle={props.spacerLeftSingle}>
			<Input
				id={props.id}
				name={props.name}
				type="radio"
				value={props.value}
				disabled={props.isDisabled}
				defaultChecked={props.defaultChecked}
				onChange={props.onChange}
			/>
			<Label htmlFor={props.id}>{props.text}</Label>
		</StyledFormSelect>
	);
}

FormSelect.propTypes = {
	id: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	name: PropTypes.string,
	text: PropTypes.string.isRequired,
	focused: PropTypes.bool,
	isDisabled: PropTypes.bool,
	defaultChecked: PropTypes.bool,
	onChange: PropTypes.func,
};

FormSelect.defaultProps = {
	value: '',
	focused: false,
	onChange: () => {},
};

export default FormSelect;
