import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../shared/styles';
import { ReactComponent as Loupe } from '../../icons/icon-loupe.svg';
import media from '../../utils/Breakpoints';

const StyledFormInput = styled.div`
	position: relative;

	+ .FormInput {
		margin-top: 12px;
	}
`;

const Label = styled.label`
	position: absolute;
	top: 15px;
	left: 15px;
	transition: all ease 0.2s;
`;

const Input = styled.input`
	margin: 0;
	padding: 10px 36px 10px 12px;
	border: 2px solid transparent;
	border-radius: 3px;
	width: 100%;
	color: ${color.dark};
	font-size: 16px;
	letter-spacing: 0.51px;
	line-height: 26px;

	&:focus {
		border-color: ${color.blue};
	}

	&:focus + ${Label} {
		transform: translate(0, -12px);
		font-size: 11px;
	}

	${props =>
		props.defaultValue &&
		`
		& + ${Label} {
			transform: translate(0, -12px);
			font-size: 11px;
		}
	`}

	${media.sl`
		width: 358px;
		height: 50px;
		padding: ${props =>
			props.labeltext ? '20px 50px 10px 12px' : '10px 50px 10px 12px'};
	`}
`;

const StyledLoupe = styled(Loupe)`
	position: absolute;
	top: 50%;
	right: 12px;
	transform: translate(0, -50%);

	${media.sl`
		right: 24px;
	`}
`;

class FormInput extends Component {
	state = {
		inputValue: this.props.value || '',
	};

	updateInputValue = event => {
		this.setState({ inputValue: event });
	};

	render() {
		const {
			id,
			name,
			// value,
			disabled,
			placeholder,
			labeltext,
			searchIcon,
			onKeyUp,
			onChange,
		} = this.props;
		const { inputValue } = this.state;

		return (
			<StyledFormInput className="FormInput">
				<Input
					id={id}
					name={name}
					type="text"
					defaultValue={inputValue}
					placeholder={placeholder}
					labeltext={labeltext}
					disabled={disabled}
					onKeyUp={e => {
						onKeyUp(e.target.value);
						this.updateInputValue(e.target.value);
					}}
					onChange={e => {
						onChange(e.target.value);
						this.updateInputValue(e.target.value);
					}}
				/>
				{labeltext ? <Label htmlFor={id}>{labeltext}</Label> : null}
				{searchIcon ? <StyledLoupe /> : null}
			</StyledFormInput>
		);
	}
}

FormInput.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	labeltext: PropTypes.string,
	searchIcon: PropTypes.bool,
	focused: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onKeyUp: PropTypes.func,
};

FormInput.defaultProps = {
	value: '',
	placeholder: '',
	labeltext: '',
	searchIcon: false,
	focused: false,
	disabled: false,
	onChange: () => {},
	onKeyUp: () => {},
};

export default FormInput;
