import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';

const StyledContentWrapperColum = styled.div`
	${'' /* outline: 1px solid blue; */}
	position: relative;
	width: 100%;
	max-width: 380px;
	margin-bottom: 48px;

	& ~ & {
		margin-bottom: 12px;
	}

	${media.sl`
		max-width: 395px;
	`}

	${media.md`
		width: 50%;
		max-width: 400px;
		margin-bottom: 24px;

		${props => props.wider ? `
			width: 60%;
			max-width: 480px;
		` : ''}

		& + & {
			margin-bottom: 0;

			${props => props.wider ? `
				width: 40%;
			` : ''}
		}
	`}
`;

function ContentWrapperColum(props) {
	return (
		<StyledContentWrapperColum wider={props.wider}>{props.children}</StyledContentWrapperColum>
	);
}

ContentWrapperColum.propTypes = {
	children: PropTypes.node,
	wider: PropTypes.bool,
};

ContentWrapperColum.defaultProps = {
	children: '',
	wider: false,
};

export default ContentWrapperColum;
