import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../shared/styles';

const StyledHeadline = styled.h2`
	color: ${props => (props.colored ? color.orange : color.black)};
	font-size: 28px;
	line-height: 36px;
	margin-top: 0;

	&:first-child {
		margin-top: 0;
	}

	& + p {
		margin-top: 24px;
	}
`;

function Headline(props) {
	return (
		<StyledHeadline colored={props.colored}>{props.text}</StyledHeadline>
	);
}

Headline.propTypes = {
	text: PropTypes.node.isRequired,
	colored: PropTypes.bool,
};

export default Headline;
