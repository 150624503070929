import { apiBase } from "./config";

const linesByIdCache = {};

export default async (id, pkKwd = '') => {
	if (linesByIdCache[id] !== undefined) {
		return linesByIdCache[id];
	}

	const response = await fetch(`${apiBase}XML_SERVINGLINES_REQUEST?SpEncId=0&coordOutputFormat=EPSG:4326&deleteAssignedStops=1&lineReqType=2&locationServerActive=1&lsShowTrainsExplicit=1&mergeDir=0&mode=odv&outputFormat=rapidJSON&serverInfo=1&type_sl=stopID&name_sl=${id}&pk_campaign=entwicklertools&pk_kwd=${pkKwd}`);
	const json = await response.json();
	linesByIdCache[id] = json.lines;
	return json.lines;
}
