import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../shared/styles';

const StyledText = styled.p`
	color: ${props => (props.bright ? color.grey : color.black)};
	font-size: ${props => (props.small ? '16px' : '18px')};
	line-height: ${props => (props.small ? '21px' : '26px')};
	font-style: ${props => (props.italic ? 'italic' : 'normal')};
	margin-bottom: ${props => (props.mb0 ? '0' : '24px')};
	text-align: ${props => (props.alignright ? 'right' : 'left')};
`;

function Text(props) {
	return <StyledText {...props}>{props.text}</StyledText>;
}

Text.propTypes = {
	text: PropTypes.node.isRequired,
	bright: PropTypes.bool,
	italic: PropTypes.bool,
	small: PropTypes.bool,
	mb0: PropTypes.bool,
	alignright: PropTypes.bool,
};

export default Text;
