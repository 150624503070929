import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';

const StyledSelectWrapper = styled.div`
	${'' /* outline: 1px solid red; */}
	display: flex;
	flex-flow: wrap;
	align-items: center;
	justify-content: flex-end;
	width: 220px;
	margin-left: 0;
	margin-top: ${props => (props.spacerTopSingle ? '12px' : '0')};
	margin-bottom: ${props => (props.spacerBottomSingle ? '12px' : '0')};

	${media.sl`
		flex-flow: nowrap;
		width: auto;
	`}
`;

function SelectWrapper(props) {
	return (
		<StyledSelectWrapper {...props}>{props.children}</StyledSelectWrapper>
	);
}

SelectWrapper.propTypes = {
	children: PropTypes.node,
	spacerTopSingle: PropTypes.bool,
};

SelectWrapper.defaultProps = {
	children: '',
};

export default SelectWrapper;
