import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, typography } from '../../shared/styles';
import media from '../../utils/Breakpoints';

const TabLabel = styled.label`
	border-bottom: 3px solid
		${props => (props.active ? color.orange : 'transparent')};
	display: inline-block;
	padding: 4px;
	list-style-type: none;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.93px;
	text-align: center;
	text-transform: uppercase;
	color: ${color.black};
	font-family: ${typography.type.bold};
	font-weight: ${typography.weight.bold};
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none; /* Likely future */
	width: 130px;
	margin-bottom: 12px;

	&:hover {
		cursor: pointer;
	}

	${media.sl`
		width: auto;
		margin-bottom: 0;
	`}
`;

function TabEntry({ active, onClick, children }) {
	return (
		<li>
			<TabLabel active={active} onClick={onClick}>
				{children ? Children.toArray(children) : ''}
			</TabLabel>
		</li>
	);
}

TabEntry.propTypes = {
	active: PropTypes.bool.isRequired,
	onClick: PropTypes.func,
	children: PropTypes.node,
};

TabEntry.defaultProps = {
	onClick: () => {},
};

export default TabEntry;
