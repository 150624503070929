import React, { Component } from 'react';

import MainWrapper from './components/MainWrapper';
import Header from './components/Header';
import Link from './components/Link';
import ContentWrapper from './components/ContentWrapper';
import ContentWrapperColum from './components/ContentWrapper/ContentWrapperColum.js';
import Footer from './components/Footer';
import Tabs from './components/Tabs';
import TabItemContent from './components/Tabs/TabItemContent.js';
import TextWrapper from './components/TextWrapper';
import SelectButtonWrapper from './components/SelectButtonWrapper';

import Headline from './components/Headline';
import Text from './components/Text';
import ImageBox from './components/ImageBox';
import Button from './components/Button';
import SelectButton from './components/SelectButton';
import StepIndicator from './components/StepIndicator';

import closeIframe from './utils/closeIframe';

import { ReactComponent as Close } from './icons/icon-close.svg';

import imageFile1 from './assets/widget_anachb.png';
import imageFile2 from './assets/widget_pendler-1.png';
import imageFile4 from './assets/stadtplan.png';
import imageFile5 from './assets/fahrplanauskunft.png';
import imageFile6 from './assets/aushangfahrplan.png';
import imageFile7 from './assets/fahrplanbuchseite.png';
import imageFile8 from './assets/desktop_abfahrtsmonitor.png';
import imageFile9 from './assets/abfahrtstafel.png';

class Schritt1 extends Component {
	constructor(props) {
		super(props);

		const locationState = props.location.state || false;

		this.state = {
			activeType: locationState ? (locationState.activeType || '') : '',
			activeTabIndex: locationState ? (locationState.activeTabIndex || '') : '',
			isActive: locationState && locationState.activeType !== '',
			nextButtonIsDisabled: locationState ? (locationState.activeType || '') === '' : true,
		};
	}

	componentDidMount() {
		window._paq = window._paq || [];
		const { _paq } = window;
		_paq.push(['setGenerationTimeMs', 0]);
		_paq.push(['enableLinkTracking']);
		_paq.push(['setDocumentTitle', 'VVS Services Assistent']);
		_paq.push(['trackPageView']);
	}

	changeActiveType = e => {
		if (this.state.activeType !== e.target.value) {
			this.setState({
				activeType: e.target.value,
				isActive: true,
				nextButtonIsDisabled: false,
			});
		} else {
			this.setState({
				activeType: '',
				isActive: false,
				nextButtonIsDisabled: true,
			});
		}
	}

	checkSwitch = param => {
		switch (param) {
			case 'abfahrten-website':
				return (
					<TextWrapper>
						<ImageBox
							src={imageFile9}
							alt="Screenshot VVS Widget Abfahrtstafel"
							width="320px"
						/>
					</TextWrapper>
				);
			case 'abfahrten-desktop':
				return (
					<TextWrapper>
						<ImageBox
							src={imageFile8}
							alt="Screenshot VVS Windows Widget"
						/>
					</TextWrapper>
				);
			case 'abfahrten-aushangfahrplan':
				return (
					<TextWrapper>
						<ImageBox
							src={imageFile6}
							alt="Screenshot Aushangfahrplan"
							width="320px"
						/>
					</TextWrapper>
				);
			case 'abfahrten-fahrplanbuchseiten':
				return (
					<TextWrapper>
						<ImageBox
							src={imageFile7}
							alt="Screenshot Fahrplanbuchseiten"
							width="320px"
						/>
					</TextWrapper>
				);
			case 'anachb-website':
				return (
					<TextWrapper>
						<ImageBox
							src={imageFile1}
							alt="Screenshot VVS Widget A nach B"
							width="320px"
						/>
					</TextWrapper>
				);
			case 'anachb-fahrplanauskunft':
				return (
					<TextWrapper>
						<ImageBox
							src={imageFile5}
							alt="Screenshot VVS Fahrplanauskunft"
							width="320px"
						/>
					</TextWrapper>
				);
			case 'stadtplan-link':
				return (
					<TextWrapper>
						<ImageBox
							src={imageFile4}
							alt="Screenshot Stadtplan Ausschnitt"
							width="320px"
						/>
					</TextWrapper>
				);
			case 'pendler-auskunft':
				return (
					<TextWrapper>
						<ImageBox
							src={imageFile2}
							alt="Screenshot VVS Pendler Widget"
							width="320px"
						/>
					</TextWrapper>
				);
			default:
				return (
					<TextWrapper>
						<Headline colored text="Auskunft selbstgemacht" />
						<Headline text="Welchen Auskunftsservice wollen Sie einbinden?" />
						<Text text="Mit unseren neuen VVS Services für Website und Desktop können Sie einfach relevante VVS-Informationen in Ihre Website integrieren." />
						<Text text="Neben individuellen Abfahrtstafeln oder Fahr­planauskünften können Sie auch Stadt­planausschnitte zum Download bereitstellen." />
						<Text text="Wenn Sie häufig dieselbe Strecke fahren, ist unser neues Pendler-Widget genau das Richtige für Sie." />
					</TextWrapper>
				);
		}
	};

	render() {
		const headerCircles = [
			{ id: 1, text: '1', state: 'isSelected' },
			{ id: 2, text: '2' },
			{ id: 3, text: '3' },
		];

		return (
			<MainWrapper>
				<Header>
					<StepIndicator circles={headerCircles} />
					<Button text="Close" onClick={closeIframe} containsIcon>
						<Close />
					</Button>
				</Header>
				<ContentWrapper>
					<ContentWrapperColum>
						{this.checkSwitch(this.state.activeType)}
					</ContentWrapperColum>
					<ContentWrapperColum>
						<Tabs
							entries={[
								'Abfahrten',
								'A nach B',
								'Stadtplan',
								'Pendler',
							]}
							activeIndex={this.state.activeTabIndex || 0}
							onTabChange={activeTabIndex => {
								let activeType = '';
								if (activeTabIndex === 2) {
									activeType = 'stadtplan-link';
								} else if (activeTabIndex === 3) {
									activeType = 'pendler-auskunft';
								}
								this.setState({
									activeTabIndex,
									activeType,
									isActive: activeType !== '',
									nextButtonIsDisabled: activeType === '',
								});
							}}
						>
							{/* Tab Abfahrten */}
							<TabItemContent>
								<SelectButtonWrapper>
									<SelectButton
										text="Abfahrtstafel für Ihre Website"
										icon="page"
										value="abfahrten-website"
										id="select-1-1"
										name="abfahrten"
										isActive={
											this.state.activeType ===
											'abfahrten-website'
										}
										onClick={this.changeActiveType}
									/>
									<SelectButton
										text="Abfahrtstafel für Ihren Desktop"
										icon="windows-pc"
										value="abfahrten-desktop"
										id="select-1-2"
										name="abfahrten"
										isActive={
											this.state.activeType ===
											'abfahrten-desktop'
										}
										onClick={this.changeActiveType}
									/>
									<SelectButton
										text="Link zum Aushang- fahrplan"
										icon="external-link"
										value="abfahrten-aushangfahrplan"
										id="select-1-3"
										name="abfahrten"
										isActive={
											this.state.activeType ===
											'abfahrten-aushangfahrplan'
										}
										onClick={this.changeActiveType}
									/>
									<SelectButton
										text="Link zu Fahrplan- buchseiten"
										icon="external-link"
										value="abfahrten-fahrplanbuchseiten"
										id="select-1-4"
										name="abfahrten"
										isActive={
											this.state.activeType ===
											'abfahrten-fahrplanbuchseiten'
										}
										onClick={this.changeActiveType}
									/>
								</SelectButtonWrapper>
							</TabItemContent>

							{/* Tab A nach B */}
							<TabItemContent>
								<SelectButtonWrapper specialForTwoItems>
									<SelectButton
										text="Routenplaner für Bahn, Bus und Fahrrad"
										icon="page"
										value="anachb-website"
										id="select-2-1"
										name="anachb"
										isActive={
											this.state.activeType ===
											'anachb-website'
										}
										onClick={this.changeActiveType}
									/>
									<SelectButton
										text="Link zur Fahrplan- auskunft"
										icon="external-link"
										value="anachb-fahrplanauskunft"
										id="select-2-3"
										name="anachb"
										isActive={
											this.state.activeType ===
											'anachb-fahrplanauskunft'
										}
										onClick={this.changeActiveType}
									/>
								</SelectButtonWrapper>
							</TabItemContent>

							{/* Tab Stadtplan */}
							<TabItemContent>
								<SelectButtonWrapper>
									<SelectButton
										text="Link zum Stadtplan- ausschnitt"
										icon="external-link"
										value="stadtplan-link"
										id="select-3-1"
										name="stadtplan"
										isActive={
											this.state.activeType ===
											'stadtplan-link'
										}
										onClick={this.changeActiveType}
									/>
								</SelectButtonWrapper>
							</TabItemContent>

							{/* Tab Pendler */}
							<TabItemContent>
								<SelectButtonWrapper>
									<SelectButton
										text="Pendler- auskunft für Ihre Website und Intranet"
										icon="page"
										value="pendler-auskunft"
										id="select-4-1"
										name="pendler"
										isActive={
											this.state.activeType ===
											'pendler-auskunft'
										}
										onClick={this.changeActiveType}
									/>
								</SelectButtonWrapper>
							</TabItemContent>
						</Tabs>
					</ContentWrapperColum>
				</ContentWrapper>
				<Footer>
					<Link
						to={{
							pathname: '/schritt2',
							state: {
								activeTabIndex: this.state.activeTabIndex || 0,
								activeType: this.state.activeType,
							},
						}}
						replaceHistory
					>
						<Button
							text="Weiter"
							isDisabled={this.state.nextButtonIsDisabled}
							handleRoute="x"
						/>
					</Link>
				</Footer>
			</MainWrapper>
		);
	}
}

export default Schritt1;
