/*
	Source: https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
*/
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTopController({ history }) {
	useEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0);
		});
		return () => {
			unlisten();
		};
		// eslint-disable-next-line
	}, []);

	return null;
}

export default withRouter(ScrollToTopController);
