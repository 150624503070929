import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../shared/styles';

const StyledIndicatorCircle = styled.div`
	background-color: ${color.greylight};
	color: ${color.white};
	width: 60px;
	height: 60px;
	border-radius: 50%;
	font-size: 22px;
	line-height: 60px;
	font-weight: bold;
	text-align: center;
	margin-right: 30px;

	&:hover {
		cursor: default;
	}

	&:last-child {
		margin-right: 0;
	}

	${props =>
		props.state === 'isClickable' &&
		`
		&:hover {
			cursor: pointer;
			background-color: ${color.grey};
		}

		&:active {
			background-color: ${color.dark};
		}
	`}

	${props =>
		props.state === 'isSelected' &&
		`
		background-color: ${color.blue};
	`}
`;

function IndicatorCircle(props) {
	return (
		<StyledIndicatorCircle state={props.state}>
			{props.text}
		</StyledIndicatorCircle>
	);
}

IndicatorCircle.propTypes = {
	id: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
	state: PropTypes.string,
};

export default IndicatorCircle;
