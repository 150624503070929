import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';

const StyledImageBox = styled.div`
	display: none;

	${media.md`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// width: 100%;
		height: 467px;
		overflow: hidden;
		position: relative;
		// margin: 0 0 48px 0;
		padding: 12px;
		background: transparent;
		width: 386px;
		margin-bottom: 0;
	`}
`;

const Image = styled.img`
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
`;

function ImageBox(props) {
	return (
		<StyledImageBox>
			<Image src={props.src} alt={props.alt} width={props.width} />
		</StyledImageBox>
	);
}

ImageBox.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
	width: PropTypes.string,
};

export default ImageBox;
