import styled from 'styled-components';

const StopItem = styled.li`
	background-color: #fff;
	border-bottom: 1px solid #ccc;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	margin: 0 auto;
	padding: 10px;
	max-width: 668px;

	&:hover {
		background-color: #ddd;
		cursor: pointer;
	}

	svg {
		flex-shrink: 0;
		width: 20px;
	}
`;

export default StopItem;
