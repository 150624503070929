import { apiBase } from "./config";

const stopsCache = {};

export default async (input, type = 'any', pkKwd = '') => {
	if (stopsCache[input] !== undefined) {
		return stopsCache[input];
	}
	const response = await fetch(`${apiBase}XML_STOPFINDER_REQUEST?coordOutputFormat=EPSG:4326&name_sf=${encodeURIComponent(input)}&type_sf=${type}&suggestApp=vvs&outputFormat=rapidJSON&pk_campaign=entwicklertools&pk_kwd=${pkKwd}`);
	const json = await response.json();
	stopsCache[input] = json.locations;
	return json.locations;
};
