import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../shared/styles';
import media from '../../utils/Breakpoints';

const StyledFormWrapper = styled.div`
	background-color: ${color.greylighter};
	padding: 18px;
	width: 100%;
	margin-top: ${props => (props.spacerTopBig ? '72px' : '0')};
	${'' /* outline: 1px solid rebeccapurple; */}

	${media.sl`
		max-width: 394px;
	`}
`;

function FormWrapper(props) {
	return <StyledFormWrapper {...props}>{props.children}</StyledFormWrapper>;
}

FormWrapper.propTypes = {
	children: PropTypes.node,
	spacerTopBig: PropTypes.bool,
};

FormWrapper.defaultProps = {
	children: '',
};

export default FormWrapper;
