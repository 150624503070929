import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';
import { color } from '../../shared/styles';

const StyledFooter = styled.div`
	${'' /* outline: 1px solid green; */}
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	width: 100%;
	padding-top: 24px;
	border-top: 1px solid #d8d8d8;

	${media.md`
		min-width: 600px;
	`}

	span {
		color: ${color.black};
		display: block;
		position: absolute;
		margin-top: -20px;
		margin-left: 20px;

		${media.sl`
			margin-left: 30px;
		`}

		${media.md`
			margin-left: -80px;
			margin-top: 17px;
		`}
	}
`;

function Footer(props) {
	return <StyledFooter>{props.children}</StyledFooter>;
}

Footer.propTypes = {
	children: PropTypes.node,
};

Footer.defaultProps = {
	children: '',
};

export default Footer;
