import styled from 'styled-components';

const TabItemContent = styled.div`
	flex-shrink: 0;
	overflow: hidden;
	width: 100%;
	min-height: 200px;
	padding: 24px 0 0 0;
`;

export default TabItemContent;
